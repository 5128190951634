<template>
  <v-dialog v-model="dialog" persistent transition="fab-transition" max-width="800" id="settings">
    <v-card tile>
      <div class="d-flex justify-space-between mb-5" id="modal-header">
        <v-card-title class="py-3 text-subtitle-1">个人信息</v-card-title>

        <v-btn icon text @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row class="pb-10">
          <v-col cols="12" sm="12" md="4" lg="4" class="text-center">
            <v-responsive width="200" height="200" class="mx-auto" style="border-radius: 50%;" @click="upImgSel">
              <div v-if="!formData.headimg" id="settings-image-placeholder">
                <v-icon>mdi-image-plus</v-icon>
              </div>
              <v-img v-else height="100%" :src="vuex_baseUrl+formData.headimg" :lazy-src="vuex_base64Avatar"></v-img>
              <v-file-input ref="upFile" hide-input @change="upImg"></v-file-input>
            </v-responsive>
            <v-btn text @click="upImgSel">上传头像</v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="8" class="pt-5">
            <form @submit.prevent="handleSubmit()" @reset.prevent="reset">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="formData.nickname" label="昵称"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.email" :disabled="formData.email==formData.username" label="邮箱">
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.mobile" :disabled="formData.mobile==formData.username" label="手机号">
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  性别
                  <v-row>
                    <v-col cols="3">
                      <v-checkbox v-model="formData.sex" :value="1" label="男"></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-checkbox v-model="formData.sex" :value="2" label="女"></v-checkbox>
                    </v-col>
                    <v-col cols="5">
                      <v-checkbox v-model="formData.sex" :value="3" label="保密"></v-checkbox>
                    </v-col>
                  </v-row>
                  <!-- <v-text-field v-model="formData.sex" label="性别"></v-text-field> -->

                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.card" label="身份证号"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.birthday" label="出生日期"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.company" label="公司"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.address" label="地址" @click.stop="cityoverlay=true"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.occupation" label="职位"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.title" label="头衔"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.autograph" label="个人签名"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="formData.website" label="个人网站"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="formData.introduce" label="自我简介"></v-text-field>
                </v-col>
              </v-row>
              <v-btn type="submit" class="primary" depressed>提交</v-btn>
            </form>
          </v-col>

        </v-row>
      </v-card-text>
      <v-snackbar :timeout="1500" v-model="isShow">
        {{showToast}}
      </v-snackbar>
      <city-select v-if="cityoverlay" @closecityselect="closecityselect" @cityChange="cityChange"
        @input="cityoverlay=false"></city-select>

    </v-card>
  </v-dialog>
</template>

<script>
// import myUpload from 'vue-image-crop-upload'
import CitySelect from "@/components/CitySelect.vue";
export default {
  components: {
    CitySelect,
  },
  name: 'SettingsModal',
  props: ['openDialog'],
  data: function () {
    return {
      show: false,
      formData: {
        mobile: '',
        email: '',
        name: '',
        headimg: '',
        sex: '',
        card: '',
        birthday: '',
        company: '',
        address: '',
        occupation: '',
        title: '',
        autograph: '',
        introduce: '',
        website: '',

      },
      isShow: false,
      showToast: '',
      cityoverlay: false, //城市选择弹框
    }
  },
  computed: {
    dialog() {
      return this.openDialog
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    cityChange(e) {
      let url = "/api/Basics/information";
      let input =
        e.province.label + "-" + e.city.label + "-" + e.area.label;
      this.$ajax
        .post(url, {
          address: input,
        })
        .then((res) => {
          this.showToast = res.msg;
          this.isShow = true;
          if (res.code == 1) {
            this.vuex("vuex_user.address", input);
          }
        });

    },
    closecityselect() {

      this.cityoverlay = false;
    },
    upImgSel() {
      this.$refs.upFile.$refs.input.click();
    },
    // 上传图片
    upImg(file) {
      if (!file) return;
      // this.imgLoading = true;
      this.upFile(file).then(res => {
        // this.imgLoading = false;
        this.formData.headimg = res.data.path;
        this.handleSubmit(1);
      });
    },
    upFile(file) {
      return new Promise((resolve, reject) => {
        let formDatas = new FormData();
        formDatas.append('file', file);
        this.$ajax.post('/api/Homepage/UpFiles', formDatas, { headers: { 'Conten-Type': 'multipart/form-data' } }).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        });
      })
    },
    getInfo() {
      let that = this;
      this.$ajax.post('/api/Basics/getinformation').then(res => {
        if (res.code == 1) {
          this.formData = res.users;
          that.vuex('vuex_user', res.users);
          let vuex_account = this.vuex_account;
          let account = vuex_account[res.users.username];
          if (!account) {
            account = { token: '', user: [] };;
          }
          account.token = this.vuex_token;
          account.user = res.users;
          vuex_account[res.users.username] = account;
          that.vuex('vuex_account', vuex_account);
        }
      })
    },
    // 提交
    handleSubmit(type) {
      this.$ajax.post('/api/Basics/information', this.formData).then(res => {
        if (res.code == 1) {
          this.getInfo();
          if (!type) {
            setTimeout(() => {
              this.closeModal();
            }, 1500)
          }
        }
        this.showToast = res.msg;
        this.isShow = true;
      })
    },
    closeModal() {
      this.$emit('closeDialog')
    },
  },
  // components: {
  //   myUpload
  // }
}
</script>

<style lang="scss">
#modal-header {
  border-bottom: 1px solid rgb(238, 232, 232);
}

#settings-image-placeholder {
  width: 100%;
  height: 100%;
  border: 2px dashed rgb(209, 209, 209);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .vue-image-crop-upload {
    .vicp-wrap {
      width: 95%;
      height: 550px;
      .vicp-step2 .vicp-crop {
        .vicp-crop-left {
          float: none;

          .vicp-range {
            margin: auto;
            margin-top: 30px;
          }

          & * {
            margin-left: auto;
            margin-right: auto;
          }
        }
        .vicp-crop-right {
          float: none;
          margin-top: 30px;
          .vicp-preview .vicp-preview-item {
            display: inline-block;
            float: none;
          }
        }
      }
    }
  }
}
</style>
