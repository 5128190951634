<template>
    <v-card elevation="2">
        <v-list :width="360" v-if="!reveal">
            <v-tabs centered class="mb-3" v-model="tabs">
                <v-tab @click="getNews(0);loadflag=true">消息</v-tab>
                <v-tab @click="getNews(1);loadflag=true">通知</v-tab>
                <v-tab @click="getNews(2);loadflag=true">评论</v-tab>
                <v-tab @click="getNews(3);loadflag=true">点赞</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
                <v-tab-item v-for="i in 4" :key="i" style="height:500px">
                    <!-- <v-virtual-scroll height="500" item-height="80" :items=""> -->
                    <my-scroll :ops="ops" @handle-scroll="handleScroll">
                        <template v-for="(item,index) in [newsList,informList,commentList,likeList][tabs]">
                            <v-list-item :key="index" @click="msgDetails(item.uid,item.id,item.type,item)">
                                <v-list-item-avatar size="50">
                                    <v-img :src="vuex_baseUrl+item.headimg" v-if="item.headimg" :lazy-src="vuex_base64Avatar"></v-img>
                                    <!-- <v-avatar color="indigo" size="50" v-else>
                    <v-icon dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar> -->
                                    <v-avatar class="white--text" v-else size="50" color="red">{{item.nickname.split('')[0]}}</v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="item.nickname"></v-list-item-title>
                                    <v-list-item-subtitle v-html="item.content"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="item.num">
                                    <div class="msgAlert">
                                        {{item.num}}
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <template cols="12" v-if="[newsList,informList,commentList,likeList][tabs].length==0&&!loadflag">
                            <div style="margin:0 auto;width:180px;text-align:center">
                                <img :src="require('@/assets/icons/mescroll-empty.png')" style="width:100%" alt="">
                                <p>暂无相关数据~</p>
                            </div>
                        </template>
                    </my-scroll>

                    <!-- </v-virtual-scroll> -->
                </v-tab-item>
            </v-tabs-items>
        </v-list>
        <!-- 消息详情 -->
        <v-card-actions class="cardHead" v-if="reveal">
            <v-btn icon @click="closeDetail()">
                <v-icon> mdi-arrow-left-thick
                </v-icon>
            </v-btn>
        </v-card-actions>
        <v-card class="cardBody" ref="cardBody" v-if="reveal" width="360" height="520">
            <div v-if="newsType==0">
                <div v-if="isMore==2" class="d-flex justify-center msgMore" @click="msgDetails(msgId)">
                    查看更多
                </div>
                <div v-else class="d-flex justify-center msgMore">
                    已无更多消息
                </div>
            </div>

            <div class="d-flex flex-column msgBox">
                <div class="py-2 px-3" :class="item.sender==vuex_user.id?'align-self-end':'align-self-start'" v-for="(item,index) in msgData" :key="index">
                    <!-- 用户间私信 -->
                    <template v-if="msgId>0">
                        <div :class="item.sender==vuex_user.id?'msg-right':''">
                            {{ $util.getDateDiff(new Date(item.time).valueOf()) }}</div>
                        <div class="msgCont" :class="item.sender==vuex_user.id?'msgCont-right':''">
                            <v-img min-height="150" max-height="150" max-width="200" v-if="item.image" :src="vuex_baseUrl+item.image">
                            </v-img>
                            <div>{{item.content}}</div>
                        </div>
                    </template>
                    <!-- 官方动态通知 -->
                    <template v-if="msgId==0">
                        <div>{{ $util.getDateDiff(new Date(item.time).valueOf()) }}</div>
                        <div class="msgCont">
                            <v-img min-height="150" max-height="150" max-width="200" v-if="item.imgid" :src="vuex_baseUrl+item.imgid">
                            </v-img>
                            <div>{{item.nickname}} 发布了 [ {{item.name}} ] </div>
                        </div>
                    </template>
                    <!-- 官方反馈通知 -->
                    <template v-if="msgId==-1">
                        <div>{{ $util.getDateDiff(new Date(item.time).valueOf()) }}</div>
                        <div class="msgCont">
                            <v-img min-height="150" max-height="150" max-width="200" v-if="item.imgid" :src="vuex_baseUrl+item.imgid">
                            </v-img>
                            <div class="pt-2">{{item.content}} </div>
                            <div>回复：{{item.reply}}</div>
                        </div>
                    </template>
                    <!-- 通知公告 -->
                    <template v-if="msgId==-2">
                        <div>{{ $util.getDateDiff(new Date(item.time).valueOf()) }}</div>
                        <div class="msgCont">
                            <div class="pt-2">{{item.content}} </div>
                        </div>
                    </template>
                </div>
            </div>
        </v-card>
        <!-- 回复 -->
        <div class="msgReply d-flex align-center px-2" v-if="msgId>0&&reveal">
            <v-text-field v-model="msgReplyText" v-on:keyup.enter="sendMsg"></v-text-field>
            <v-btn slot="append" class="ml-2" style="background: #19be6b;color:#fff" @click="sendMsg">发送</v-btn>
        </div>

        <v-snackbar :timeout="1500" v-model="isShow">
            {{ showToast }}
        </v-snackbar>
    </v-card>
</template>

<script>
import { createLogger } from 'vuex';
export default {
    props: {},
    data: () => ({
        isShow: false,
        showToast: '',
        newsList: [], //消息
        informList: [], //通知
        commentList: [], //评论
        likeList: [], //点赞
        newsType: 0,
        page: 1,
        reveal: false,   //详情
        msgId: '',
        msgData: [],
        msgPage: 1,
        msgReplyText: '',
        isMore: '',
        tabs: 0,
        ops: {
            scrollPanel: {
                scrollingX: false,
            },
            rail: {},
            bar: {
                background: '#929292'
            }
        },
        loadflag: true,
        scrollTop: 0,
    }),
    created() {
        this.getNews(0)
    },
    methods: {
        closeDetail() {
            this.reveal = false;
            this.msgPage = 1;
            if (this.msgId != -2) {
                this.getNews(0);
            }

        },
        //发送私信
        sendMsg() {
            let that = this;
            if (that.msgReplyText != '') {
                this.$ajax.post('/api/Operation/message', {
                    leader: that.msgId,
                    content: that.msgReplyText
                }).then(res => {
                    if (res.code == 1) {
                        that.$refs.cardBody.$el.scrollTop = that.$refs.cardBody.$el.scrollHeight;
                        that.msgPage = 1;
                        that.msgReplyText = '';
                        that.msgDetails(that.msgId);
                    } else {
                        this.showToast = res.msg;
                        this.isShow = true;
                    }
                })
            }
        },
        handleScroll(vertical, horizontal, nativeEvent) {
            // console.log(vertical, horizontal, nativeEvent,2)
            let that = this
            let Scroll = nativeEvent.target
            // 网页可见区域高：document.body.clientHeight
            // 网页正文全文高：document.body.scrollHeight
            let scrollHeight = Scroll.scrollHeight - Scroll.clientHeight
            // that.scrollTop = Scroll.scrollTop
            // console.log(scrollHeight,Scroll.scrollTop);
            if (scrollHeight - Scroll.scrollTop < 100 && !that.loadflag) {
                // console.log('到底部了')
                that.loadflag = true;
                that.page++
                that.getNews(that.newsType)
            }
        },
        // 获取消息列表
        getNews(type) {
            let that = this;
            let getMsgList = {
                0: '/api/News/messagelist',
                1: '/api/News/notice',
                2: '/api/News/commentlist',
                3: '/api/News/works'
            }
            if (type != this.newsType && type >= 0) {
                this.newsType = type;
                this.page = 1;
            }
            // if (this.vuex_user) {
            this.$ajax.post(getMsgList[this.newsType], {
                page: this.page,
                limit: 10
            }).then(res => {
                if (!res.data) {
                    res.data = [];
                }
                if (type == 0) {
                    if (that.page == 1) that.newsList = [];
                    that.newsList = that.newsList.concat(res.data);
                } else if (type == 1) {
                    if (that.page == 1) that.informList = [];
                    that.informList = that.informList.concat(res.data);
                } else if (type == 2) {
                    if (that.page == 1) that.commentList = [];
                    that.commentList = that.commentList.concat(res.data);
                } else if (type == 3) {
                    if (that.page == 1) that.likeList = [];
                    that.likeList = that.likeList.concat(res.data);
                }
                this.loadflag = false;
                this.$forceUpdate();
            })
            // }
        },
        toDetails(id, type) {
            if (type && id) {
                let modearr = ["video", "art", "photo"];
                this.vuex("vuex_mode", modearr[type - 1]);
                this.$router.push('/detail/' + id);
            }
        },
        // 获取消息详情
        msgDetails(id, wid, type, data) {
            let that = this;
            if (that.newsType != 0) {
                // console.log(11);
                if (that.newsType == 3 || that.newsType == 2) {
                    that.toDetails(wid, type);
                }
                if (that.newsType == 1) {
                    this.msgId = -2;
                    that.msgData = []
                    that.msgPage == 1;
                    this.reveal = true;
                    that.msgData.push(data);
                    console.log(that.msgData);
                    this.$forceUpdate();
                }
                return;
            }
            let url = '/api/News/messagedetail';
            if (id != this.msgId) {
                this.msgId = id;
                this.msgPage = 1;
                this.isMore = '';
            }
            if (id == -1) {
                url = "/api/News/feedbackdetail";
            }
            this.$ajax.post(url, {
                id: this.msgId,
                page: that.msgPage

            }).then(res => {
                this.reveal = true;
                if (that.msgPage == 1) { that.msgData = [] }
                for (let i = res.data.length - 1; i >= 0; i--) {
                    that.msgData.unshift(res.data[i]);
                }
                // console.log(that.msgData);

                if (res.page.maxPage > that.msgPage) { this.isMore = 2 }
                else {
                    this.isMore = 1
                }
                that.msgPage++;
            })
        }
    }
};
</script>

<style scoped>
.msgBox {
    padding-bottom: 120px;
}
.msgAlert {
    background: linear-gradient(-6deg, #000000, #7a7a7a);
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    padding: 0 5px;
}
.msgReply {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
}
.msg-right {
    text-align: right;
}

.msgMore {
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 10px;
}
.v-virtual-scroll::-webkit-scrollbar {
    width: 5px;
    background: #ededed;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}
.cardBody {
    overflow-y: scroll;
}
.cardBody::-webkit-scrollbar {
    width: 5px;
    background: #ededed;
}
.cardBody::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}
.msgCont {
    background: #f1f2f3;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 5px;
    max-width: 230px;
}
.msgCont-right {
    background: #19be6b;
    color: #fff;
}
.msgCont .v-image {
    margin-bottom: 5px;
}
</style>
