<template>
    <v-app>
        <router-view name="NavBar"></router-view>
        <router-view name="StudioNavBar"></router-view>
        <v-main :class="{
                'content-bg': ![
                    'SignIn',
                    'SignUp',
                    'Dashboard',
                    'Video',
                    'Detail'
                ].includes(this.$route.name)
                    ? true
                    : false,
                    
                'backnone':$vuetify.theme.dark?true:false
            }">
            <!-- :style="{background:$vuetify.theme.dark?'none !important':'#f9f9f9'}" -->
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",
    computed: {},
    created() { },
    mounted() {
        this.getreportlist();
        // console.log("this.$route", this.$route);
    },
    methods: {
        getreportlist() {
            let url = "/api/Operation/reportfield";
            this.$ajax
                .post(url, {
                    // name: this.collectTxt,
                    // privacy: this.switch1,
                })
                .then((res) => {
                    this.vuex("vuex_reportLists", res.reportfield);
                    // this.reportLists = res.reportfield;
                });
        },
        ts() {
            this.rq
                .get(
                    "/api/product/get?productChannelId=" + this.productChannelId
                )
                .then((res) => {
                    console.log(res);
                });

            // 传对象参数
            // get请求记得加params
            this.rq
                .get("/api/product/get", { params: { name: "abc" } })
                .then((res) => {
                    console.log(res);
                });

            this.rq.post("/api/product/get", { name: "abc" }).then((res) => {
                console.log(res);
            });
        },
    },
};
</script>

<style lang="scss">
.mescroll-empty .empty-icon {
    width: 200px !important;
}
.theme--light .content-bg {
    background-color: #f9f9f9;
}

.theme--dark .content-bg {
    background-color: none !important;
}

.card {
    background: #f9f9f9 !important;
}
.v-avatar .v-image__image--preload {
    filter: blur(0px);
}
.cursor {
    cursor: pointer;
}
.contain{
    object-fit: contain;
}
</style>
