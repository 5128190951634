var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('router-view',{attrs:{"name":"NavBar"}}),_c('router-view',{attrs:{"name":"StudioNavBar"}}),_c('v-main',{class:{
            'content-bg': ![
                'SignIn',
                'SignUp',
                'Dashboard',
                'Video',
                'Detail'
            ].includes(this.$route.name)
                ? true
                : false,
                
            'backnone':_vm.$vuetify.theme.dark?true:false
        }},[_c('keep-alive',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }