import Vue from 'vue'
import VueRouter from 'vue-router'
import NavBar from '@/components/NavBar.vue'
import StudioNavBar from '@/components/StudioNavBar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      NavBar,
      default: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Detail.vue')
    }
  },
  // {
  //   path: '/ss',
  //   name: 'Homes',
  //   components: {
  //     NavBar,
  //     default: () => import(/* webpackChunkName: "about" */ '../views/list.vue')
  //   }
  // },
  {
    path: '/signin',
    name: 'Signin',
    component: () =>
      import(/* webpackChunkName: "signin" */ '../views/Auth/SignIn.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/Auth/SignUp.vue')
  },
  {
    path: '/binding',
    name: 'binding',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/Auth/binding.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () =>
      import(/* webpackChunkName: "signup" */ '../views/Auth/reset.vue')
  },
  {
    path: '/trending',
    name: 'Trending',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "trending" */ '../views/Trending.vue')
    }
  },
  {
    path: '/explore',
    name: 'Explore',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "trending" */ '../views/Explore.vue')
    }
  },
  {
    path: '/studio',
    components: {
      StudioNavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Studio/Index.vue')
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Studio/Dashboard.vue'
          )
      },
      {
        path: 'videos',
        name: 'Video',
        component: () =>
          import(/* webpackChunkName: "video" */ '../views/Studio/Video.vue')
      },

    ]
  },
  {
    path: '/channels/:id',
    name: 'Channels',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Channel/Home.vue')
    },
  },
  {
    path: '/collect',
    name: 'Collect',
    components: {
      NavBar,
      default: () =>
        import(
        /* webpackChunkName: "dashboard" */ '../views/Channel/Collect.vue'
        )
    },
  },
  {
    path: '/watch/:id',
    name: 'Watch',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Watch.vue')
    }
  },

  {
    path: '/history',
    name: 'History',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/History.vue')
    }
  },
  {
    path: '/search',
    name: 'Search',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "video" */ '../views/Search.vue')
    }
  },
  {
    path: '/issue',
    components: {
      NavBar,
      default: () =>
        import(/* webpackChunkName: "dashboard" */ '../views/Issue/Index.vue')
    },
    children: [
      {
        path: '/',
        name: 'Videos',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Issue/Video.vue'
          )
      },
      {
        path: '/photo',
        name: 'Photo',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Issue/Photo.vue'
          )
      },
      {
        path: '/article',
        name: 'Article',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '../views/Issue/Article.vue'
          )
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   // eslint-disable-next-line
//   if (_hmt) {
//     if (to.path) {
//       // eslint-disable-next-line
//       _hmt.push(['_trackPageview', to.fullPath]);
//     }
//   }
//   next();
// });
export default router
