import {
    mapState
} from 'vuex'
import store from "@/store"
// 暂时不用vuex模块方式实现，将该方法直接放入到/store/index.js中

// 尝试将用户在根目录中的store/index.js的vuex的state变量，全部加载到全局变量中
let $uStoreKey = [];
try {
    $uStoreKey = store.state ? Object.keys(store.state) : [];
} catch (e) {

}
const module = {
    actions: {
        $uStore({
            rootState
        }, params) {
            let nameArr = params.name.split('.');
            if (nameArr.length >= 2) {
                let obj = rootState[nameArr[0]];
                for (let i = 1; i < nameArr.length - 1; i++) {
                    obj = obj[nameArr[i]];
                }
                obj[nameArr[nameArr.length - 1]] = params.value;
            } else {
                rootState[params.name] = params.value;
            }
        }
    },
    beforeCreate() {
        // 将vuex方法挂在到$u中
        // 使用方法为：如果要修改vuex的state中的user.name变量为"史诗" => this.$u.vuex('user.name', '史诗')
        // 如果要修改vuex的state的version变量为1.0.1 => this.$u.vuex('version', '1.0.1')
        this.vuex = (name, value) => {
            this.$store.commit('$uStore', {
                name,
                value
            })
        }
    },
    computed: {
        // 将vuex的state中的所有变量，解构到全局混入的mixin中
        ...mapState($uStoreKey)
    }
}

export default module