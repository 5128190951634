<template>
    <v-dialog v-model="dialog" persistent transition="fab-transition" max-width="600" id="settings">
        <v-card>
            <div class="d-flex justify-space-between" id="modal-header">
                <v-card-title class="py-3 text-subtitle-1">{{'发布'+addType.name}}</v-card-title>
                <v-btn icon text @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-5 cardBody">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <template v-if="!flag">
                        <div class="input-lable">创建频道</div>
                        <v-text-field solo flat v-model="folderName" label="频道名(必填)" background-color="#f5f5f5" :rules="folderRules" required></v-text-field>
                    </template>
                    <div class="input-lable">标题</div>
                    <v-text-field solo flat v-model="title" label="标题(必填)" background-color="#f5f5f5" :rules="titleRules" required></v-text-field>
                    <template v-if="addType.id==1">
                        <div class="input-lable">视频文件</div>
                        <v-file-input ref="videoInp" solo flat background-color="#f5f5f5" accept="video/*" :rules="videoRules" :loading="videoLoading" show-size label="视频文件(必填)" @change="upVideo" @click:clear="clearVideo()" required>
                        </v-file-input>
                    </template>
                    <template v-if="addType.id==2">
                        <div class="input-lable">封面图</div>
                        <v-file-input ref="imgInp" solo flat chips background-color="#f5f5f5" :rules="imgRules" :loading="imgLoading" show-size label="封面图(必填)" @change="upImg" @click:clear="clearImg()" required accept="image/*"></v-file-input>
                    </template>
                    <template v-if="addType.id==3">
                        <div class="input-lable">图片集</div>
                        <v-file-input ref="imgInp" solo flat multiple chips background-color="#f5f5f5" :rules="imgRules" :loading="imgLoading" show-size label="图片集(必填)" @change="upImg" @click:clear="clearImg()" required accept="image/*"></v-file-input>
                    </template>

                    <div class="input-lable">标签</div>
                    <v-chip class="ma-1" label :close="item.type=='diy'" @click:close="delTab(index)" @click="labelClick(index)" v-for="(item,index) in labelList" :key="index" :text-color="item.checked?'#fff':''" :color="item.checked?'#222':'#f5f5f5'">
                        {{item.lname}}
                    </v-chip>
                    <div class="diyTab d-flex align-center mt-4">
                        <v-text-field solo outlined flat dense hide-details v-model="diyTab" label="添加一个自定义标签" class="mr-2"></v-text-field>
                        <v-btn elevation="2" small :disabled="diyTab==''" @click="addTab" color="#222" style="width: auto;padding:10px 0px;height: 34px;min-width: 40px;">
                            <v-icon color="#fff">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                    <div v-show="addType.id==2" class="mt-6">
                        <div class="input-lable">内容</div>
                        <div id="wangdiv"></div>
                    </div>
                    <v-btn class="mt-15 mb-5 subBtn" tile color="#222" @click="submitAdd()">
                        提交
                    </v-btn>
                    <div class="clear"></div>
                </v-form>
            </div>
            <video style="display:none;" controls="controls" ref="videoBox" :src="vuex_baseUrl+video.path">
            </video>
            <v-snackbar :timeout="1500" v-model="isShow">
                {{showToast}}
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>

<script>
import { createLogger } from 'vuex';
import Editor from 'wangeditor';
var editor;
export default {
    name: 'AddModal',
    props: ['openDialog', 'type'],
    data: function () {
        return {
            isShow: false,
            showToast: '',
            labelList: [],
            valid: true,
            title: '',
            titleRules: [
                v => !!v || '标题不能为空'
            ],

            video: '',
            videoRules: [
                value => !!value || '视频不能为空',
                value => !value || value.size < 50000000 || '视频不能大于50MB！'
            ],
            img: '',
            imgRules: [
                value => !!value || '图集不能为空',
            ],
            imgLoading: false,
            videoLoading: false,
            diyTab: '',
            flag: '', //频道id
            folderName: '',//创建频道名称
            folderRules: [v => !!v || '频道名称不能为空'],
            notClick: false,
        }
    },
    
    watch:{
        type(val){
            let that=this;
            console.log('type',val);
            if(val.id==2&&!editor){
                this.$nextTick(()=>{
                    that.editorInit();
                })
            }else{
                console.log(editor);
            }
        }
    },
    computed: {
        dialog() {
            return this.openDialog;
        },
        addType() {
            this.getFolder(this.type.id);
            return this.type;
        }
    },
    updated() {

    },
    created() {
        this.getLabel();
    },
    methods: {
        editorInit() {
            let that = this;
            editor = new Editor('#wangdiv');
            editor.config.withCredentials = true;
            editor.config.uploadImgServer = '/api/Homepage/UpFiles'
            editor.config.height = 500;
            editor.config.zIndex = 1;
            editor.config.uploadFileName = 'file';
            editor.config.uploadImgHeaders = {
                token: this.vuex_token
            }
            editor.config.uploadImgHooks = {
                customInsert: function (insertImgFn, result) {
                    // result 即服务端返回的接口
                    console.log('customInsert', result)
                    // editor.focus()

                    let str=`<img src="`+that.vuex_baseUrl+result.data.path+`" width="100%"/>`
                    editor.txt.append(str)
                    // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
                    // insertImgFn(that.vuex_baseUrl + result.data.path)
                }
            }
            editor.create()
        },
        // 创建频道
        addFolder() {
            if (this.folderName == '') {
                return false;
            }
            this.$ajax.post('/api/Homepage/folder', {
                type: this.addType.id,
                name: this.folderName
            }).then(res => {
                if (res.code == 1) {
                    this.flag = res.id;
                    this.submitAdd();
                }
            })
        },
        // 查看是否创建
        getFolder(type) {
            this.$ajax.get('/api/Homepage/getfolder?type=' + type).then(res => {
                if (res.code == 1) {
                    this.flag = res.id;
                } else {
                    this.flag = '';
                }
            })
        },
        closeModal() {
            this.$emit('closeDialog')
        },
        // 获取标签
        getLabel() {
            this.$ajax.post('/api/Index/getlabel').then(res => {
                if (res.code == 1) {
                    let data = res.data;
                    for (let i in data) {
                        data[i].checked = false;
                    }
                    this.labelList = data;
                }
            })
        },
        delTab(index) {
            // console.log(index);
            this.labelList.splice(index, 1);
        },
        addTab() {
            let obj = {
                lname: this.diyTab,
                checked: true,
                type: 'diy'
            }
            this.labelList.push(obj);
            this.diyTab = '';
        },
        labelClick(index) {
            this.labelList[index].checked = !this.labelList[index].checked;
        },
        // 上传视频
        upVideo(file) {
            if (!file) return;
            if (!file.type.match("video")) {
                this.showToast = '格式不符';
                this.isShow = true;
                this.$refs.videoInp.reset();
                return
            }
            this.videoLoading = true;
            this.upFile(file).then(res => {
                this.videoLoading = false;
                this.video = res.data;
                setTimeout(() => {
                    var video = this.$refs.videoBox;
                    this.video.width = video.videoWidth;
                    this.video.duration = video.duration;
                    this.video.height = video.videoHeight;
                }, 800)
            });
        },
        upFile(file) {
            return new Promise((resolve, reject) => {
                let formDatas = new FormData();
                formDatas.append('file', file);
                this.$ajax.post('/api/Homepage/UpFiles', formDatas, { headers: { 'Conten-Type': 'multipart/form-data' } }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                });
            })
        },
        // 上传图片
        upImg(file) {
            if (!file) return;
            let that = this;

            if (!file.map) {
                file = [file];
            }
            file.map(item => {
                if (!item.type.match("image")) {
                    that.img = '';
                    that.showToast = '格式不符';
                    that.isShow = true;
                    that.$refs.imgInp.reset();
                    return
                }
            })
            let imgList = [];
            for (let i = 0; i < file.length; i++) {
                this.imgLoading = true;
                this.upFile(file[i]).then(res => {
                    imgList.push(res.data.id);
                    if (imgList.length == file.length) {
                        this.imgLoading = false;
                    }
                });
            }
            this.img = imgList;
        },
        submitAdd() {
            let that = this;
            if (that.notClick) {
                return;
            }
            if (!this.$refs.form.validate()) return;
            if (!this.flag) {
                this.addFolder();
                return;
            }
            let diyT = [];
            let type = [];
            let list = this.labelList;
            for (let i in list) {
                if (list[i].checked) {
                    if (list[i].type) {
                        diyT.push(list[i].lname);
                    } else {
                        type.push(list[i].id);
                    }
                }
            }
            let url = '/api/Homepage/uploadvideo';

            let data = {}
            console.log(this.img);
            if (this.addType.id == 1) {
                data = {
                    cid: this.flag,
                    name: this.title,
                    video: this.video.path,
                    width: this.video.width,
                    height: this.video.height,
                    duration: this.video.duration || 0,
                    introduction: this.intro,
                    type: type.join(','),
                    custom: diyT.join(',')
                }
            }
            if (this.addType.id == 2) {
                url = '/api/Homepage/uploadarticle';
                data = {
                    sid: this.flag,
                    name: this.title,
                    imgid: this.img[0],
                    article: editor.txt.html(),
                    number: editor.txt.text().length,
                    type: type.join(','),
                    custom: diyT.join(',')
                }
            }
            if (this.addType.id == 3) {
                url = '/api/Homepage/uploadpicture';
                data = {
                    pid: this.flag,
                    name: this.title,
                    imgid: this.img.join(',') || '',
                    type: type.join(','),
                    custom: diyT.join(',')
                }
            }
            that.notClick = true;
            this.$ajax.post(url, data).then(res => {
                let url = '/channels/' + that.vuex_user.id
                if (res.code == 1) {
                    for (let i in that.labelList) {
                        that.labelList[i].checked = false;
                    }
                    that.$refs.form.reset();
                    if (that.addType.id == 1) {
                        that.$refs.videoInp.$refs.input.value = '';
                    }
                    if (that.addType.id == 3 || that.addType.id == 2) {
                        that.$refs.imgInp.$refs.input.value = '';
                    }
                    if (that.addType.id == 2) {
                        editor.txt.clear();
                    }
                    setTimeout(function () {
                        that.$router.push(url);
                        that.$emit('closeDialog')
                    }, 1500)

                }
                that.notClick = false;
                that.isShow = true;
                that.showToast = res.msg;
            })
        },
        clearVideo() {
            this.videoLoading = false;
            this.video = '';
        },
        clearImg() {
            this.imgLoading = false;
            this.img = '';
        },
    },
}
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}
.cardBody {
    overflow-y: scroll;
    height: 500px;
    & ::v-deep .v-input__slot {
        .v-label {
            font-size: 14px;
        }
        input {
            font-size: 15px;
        }
    }
}
.cardBody::-webkit-scrollbar {
    width: 5px;
    background: #ededed;
}
.cardBody::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}

.input-lable {
    color: #222;
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
    padding-left: 10px;
}
.input-lable:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 15px;
    top: 3px;
    left: 0;
    background-color: #222;
}
.subBtn {
    float: right;
    color: #fff;
    width: 160px;
}
</style>
