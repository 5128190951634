import Vue from 'vue'
import { required, email, size } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import Vuebar from 'vuebar'

setInteractionMode('eager')

extend(
  'required',
  {
    ...required,
    message: '{_field_} 不能为空'
  }
)
extend('phone', {
  validate(value) {
    return (/^1[123456789]\d{9}$/.test(value))
  },
  message: '请输入正确的手机号码'
})
extend('account', {
  validate(value) {
    return (/^1[123456789]\d{9}$/.test(value) || /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))
  },
  message: '请输入正确的邮箱或手机号码'
})

extend('email', email) // 邮箱校验
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: '两次密码不一致'
})

extend('size', {
  ...size,
  message: 'video size should be less than 5 MB!'
})



Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.use(Vuebar)