import Vue from 'vue';
import Vuetify from 'vuetify/lib';

let vuetify = new Vuetify({
    theme: {
        options: { customProperties: true },
      }
})

Vue.use(Vuetify);



export default vuetify;