<template>
    <v-card width="350">
        <template v-if="winId == 0">
            <v-list>
                <v-list-item @click="settingsDialog = true">
                    <v-list-item-avatar>
                        <v-img :src="vuex_baseUrl + vuex_user.headimg" :lazy-src="vuex_base64Avatar" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ vuex_user.nickname }}</v-list-item-title>
                        <v-list-item-subtitle>{{
              vuex_user.username
            }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item @click="toMenu(1)">
                    <v-list-item-icon>
                        <v-icon>mdi-account-check-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>实名认证</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(2)">
                    <v-list-item-icon>
                        <v-icon>mdi-view-grid-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>我的主页</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(3)">
                    <v-list-item-icon>
                        <v-icon>mdi-heart-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>我的喜好</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(4)">
                    <v-list-item-icon>
                        <v-icon>mdi-star-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>我的收藏</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(5)">
                    <v-list-item-icon>
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>功能设置</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(6)">
                    <v-list-item-icon>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>协议与公约</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(7)">
                    <v-list-item-icon>
                        <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>反馈与帮助</v-list-item-title>
                </v-list-item>
                <v-list-item @click="toMenu(8)">
                    <v-list-item-icon>
                        <v-icon>mdi-swap-horizontal</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>切换账号</v-list-item-title>
                </v-list-item>
                <v-list-item @click="quit()">
                    <v-list-item-icon>
                        <v-icon>mdi-login-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>退出登录</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
        <v-toolbar v-if="winId > 0">
            <v-btn icon @click="winId = 0">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title class="text-subtitle-1">{{
          [
            "",
            "实名认证",
            "我的主页",
            "我的喜欢",
            "我的收藏",
            "功能设置",
            "协议与公约",
            "反馈与帮助",
            "切换账号",
          ][winId]
        }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <!-- 实名认证 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 1">
            <v-container fluid>
                <!-- <v-text-field label="姓名" v-model="name" placeholder="请填写您的姓名" solo flat background-color='#EFEFEF'></v-text-field> -->
                <!-- <v-text-field label="性别" v-model="sex" placeholder="请填写您的性别" solo flat background-color='#EFEFEF'></v-text-field> -->
                <v-text-field label="身份证" :disabled="acceptData.examine == 2 || acceptData.examine == 1" v-model="code" placeholder="请填写您的身份证号" filled flat background-color="#EFEFEF">
                </v-text-field>
                <v-card-text class="pt-1 pl-2">
                    请拍摄并上传您的身份证图片
                </v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-img height="120" :src="
                front.path
                  ? vuex_baseUrl + front.path
                  : require('@/assets/user/smrz1.png')
              " @click="
                acceptData.examine == 0 || acceptData.examine == 3
                  ? upImg(1)
                  : ''
              "></v-img>
                    </v-col>
                    <v-col cols="6">
                        <v-img height="120" :src="
                back.path
                  ? vuex_baseUrl + back.path
                  : require('@/assets/user/smrz2.png')
              " @click="acceptData.examine == 0 || acceptData.examine == 3 ? upImg(2): ''
              "></v-img>
                    </v-col>
                </v-row>
                <input type="file" accept="image/png,image/jpeg,image/jpg,image/gif" ref="selectFile" name="file" @change="fileChange($event)" hidden />
                <v-btn elevation="2" block class="mt-16 white--text" color="#000000" @click="accept()" v-if="acceptData.examine == 0 || acceptData.examine == 3">
                    <template v-if="acceptData.examine == 0">认证</template>
                    <template v-else>审核失败，重新上传</template>
                </v-btn>
                <v-btn elevation="2" block class="mt-16 white--text" disabled color="#000000" v-if="acceptData.examine == 1 || acceptData.examine == 2">
                    <template v-if="acceptData.examine == 1">审核中</template>
                    <template v-else>已通过</template>
                </v-btn>
            </v-container>
        </v-card>
        <!-- 我的喜欢 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 3">
            <v-container fluid class="taCont" style="height:540px">
                <v-row dense>
                    <v-col v-for="card in userLike" :key="card.id" :cols="6">
                        <v-card>
                            <v-img :src="vuex_baseUrl + card.imgid" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="120px">
                                <v-card-title v-text="card.name" class="text-subtitle-1"></v-card-title>
                            </v-img>

                            <v-card-actions class="d-flex justify-end">
                                <v-btn icon>
                                    <v-icon :color="card.status == 1 ? 'red' : ''" @click="setLike(card.id)">mdi-heart</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <!-- 设置 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 5">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>账号</v-list-item-title>
                    <v-list-item-subtitle>选择账号展现方式</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon color="grey lighten-1">mdi-chevron-down</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item two-line>
                <v-list-item-content>
                    <v-list-item-title>语言</v-list-item-title>
                    <v-list-item-subtitle>中文简体</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>主题切换</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon color="grey lighten-1">mdi-chevron-down</v-icon>
                </v-list-item-action>
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>黑色/白色</v-list-item-title>
                    <v-list-item-subtitle>点击后切换主题</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-switch v-model="switch1" @change="$vuetify.theme.dark = !$vuetify.theme.dark"></v-switch>
                </v-list-item-action>
            </v-list-item>
        </v-card>
        <!-- 协议 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 6">
            <v-tabs v-model="model" centered>
                <v-tab v-for="(item, i) in ['用户协议', '社区公约', '隐私']" :key="i" :href="`#tab-${i}`">
                    {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items class="taCont" v-model="model">
                <v-tab-item v-for="(item, index) in agree" :key="index" :value="`tab-${index}`">
                    <v-card flat>
                        <v-card-text v-html="item"></v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <!-- 反馈 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 7">
            <v-container fluid>
                <v-textarea v-model="feedback">
                    <template v-slot:label>
                        <div>请输入您的意见或建议</div>
                    </template>
                </v-textarea>
                <v-row>
                    <v-col cols="6">
                        <v-img width="100" height="100" :src="
                feedbackImg.path
                  ? vuex_baseUrl + feedbackImg.path
                  : require('@/assets/user/add.png')
              " @click="upImg(3)">
                        </v-img>
                    </v-col>
                </v-row>
                <input type="file" accept="image/png,image/jpeg,image/jpg,image/gif" ref="selectFile" name="file" @change="fileChange($event)" hidden />
                <v-btn elevation="2" block class="mt-16 white--text" color="#000000" @click="subFeedback()">
                    提交
                </v-btn>
            </v-container>
        </v-card>
        <!-- 切换账号 -->
        <v-card elevation="2" height="542" width="100%" v-if="winId == 8">
            <v-list>
                <v-list-item v-for="(item, index) in vuex_account" :key="index" @click="exchange(item.token)">
                    <v-list-item-avatar size="50">
                        <v-img :src="vuex_baseUrl + item.user.headimg" v-if="item.user.headimg" :lazy-src="vuex_base64Avatar"></v-img>
                        <v-avatar color="indigo" size="50" v-else>
                            <v-icon dark> mdi-account-circle </v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="item.user.nickname"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon v-show="vuex_token == item.token"> mdi-check </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item router to="/signin">
                    <v-list-item-avatar>
                        <v-icon size="30"> mdi-account-plus-outline </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>添加账号</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon> mdi-chevron-right </v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-card>

        <v-snackbar :timeout="1500" v-model="isShow">
            {{ showToast }}
        </v-snackbar>
        <settings-modal :open-dialog="settingsDialog" v-on:closeDialog="modelClose" />
    </v-card>
</template>

<script>
import SettingsModal from "@/components/SettingsModal";
export default {
    components: {
        SettingsModal,
    },
    props: ['wid'],
    watch: {
        wid(val) {
            this.winId = val 
        }
    },
    data: () => ({
        winId: 0,
        model: "",
        agree: [],
        userLike: [],
        code: "",
        onUp: "", //当前选择上传 1正面 2反面
        front: { id: "", path: "" }, //身份证正面
        back: { id: "", path: "" }, //身份证背面
        acceptData: "", //账号认证信息
        feedback: "", //反馈
        feedbackImg: { id: "", path: "" }, //反馈图片
        isShow: false,
        showToast: "",
        switch1: "", //主题设置
        settingsDialog: false,
    }),
    created() {
        this.winId=this.wid;
        this.getAgree();
        this.getLike();
        this.getAccept();
    },
    methods: {
        //退出登录
        quit() {
            this.vuex("vuex_token", "");
            this.vuex("vuex_user", "");
            this.$router.push("/signin");
        },
        modelClose() {
            this.settingsDialog = false;
        },
        upImg(i) {
            this.onUp = i;
            this.$refs.selectFile.click();
        },
        // 切换账号
        exchange(token) {
            this.vuex("vuex_token", token);
            // this.getUserInfo();
            this.$router.replace("/");
            this.$emit("getinfo", "");
        },
        // 获取个人信息
        getUserInfo() {
            let that = this;
            this.$ajax.post("/api/Basics/getinformation").then((res) => {
                if (res.code == 1) {
                    that.vuex("vuex_user", res.users);
                    let vuex_account = this.vuex_account;
                    let account = vuex_account[res.users.username];
                    if (!account) {
                        account = { token: "", user: [] };
                    }
                    account.token = this.vuex_token;
                    account.user = res.users;
                    vuex_account[res.users.username] = account;
                    that.vuex("vuex_account", vuex_account);
                }
            });
        },
        // 获取认证状态
        getAccept() {
            this.$ajax.post("/api/Basics/realnamestatus").then((res) => {
                if (res.code == 1) {
                    this.acceptData = res.data;
                    this.code = res.data.card;
                    this.front.path = res.data.cardfrontimg;
                    this.back.path = res.data.cardafterimg;
                    console.log(this.acceptData);
                }
            });
        },
        // 提交实名认证
        accept() {
            this.$ajax
                .post("/api/Basics/authentication", {
                    card: this.code,
                    cardfrontimg: this.front.path,
                    cardafterimg: this.back.path,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.front = { id: "", path: "" };
                        this.back = { id: "", path: "" };
                        this.code = "";
                        this.showToast = "已提交审核，请耐心等待";
                    } else {
                        this.showToast = res.msg;
                    }
                    this.isShow = true;
                });
        },
        // 提交反馈
        subFeedback() {
            this.$ajax
                .post("/api/Operation/feedback", {
                    content: this.feedback,
                    imgid: this.feedbackImg.path,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.feedbackImg = { id: "", path: "" };
                        this.feedback = "";
                        this.showToast = "已提交";
                    } else {
                        this.showToast = res.msg;
                    }
                    this.isShow = true;
                });
        },
        fileChange(e) {
            const file = e.target.files[0];
            if (!file) return;
            let formDatas = new FormData();
            formDatas.append("file", file);
            this.$ajax
                .post("/api/Homepage/UpFiles", formDatas, {
                    headers: { "Conten-Type": "multipart/form-data" },
                })
                .then((res) => {
                    if (res.code == 1) {
                        if (this.onUp == 1) {
                            this.front.id = res.id;
                            this.front.path = res.data.path;
                        } else if (this.onUp == 2) {
                            this.back.id = res.id;
                            this.back.path = res.data.path;
                        } else if (this.onUp == 3) {
                            this.feedbackImg.id = res.id;
                            this.feedbackImg.path = res.data.path;
                        }
                    }
                });
        },
        toMenu(index) {
            if (index == 2) {
                this.$router.push("/channels/" + this.vuex_user.id);
                this.$emit("close", "");
            } else if (index == 4) {
                this.$router.push("/collect");
                this.$emit("close", "");
            } else {
                this.winId = index;
            }
        },
        getLike() {
            this.$ajax.post("/api/Basics/interesting").then((res) => {
                this.userLike = res.list;
            });
        },
        setLike(id) {
            let lable = this.userLike;
            let likeLable = [];
            for (let i in lable) {
                if (lable[i].id == id) {
                    lable[i].status == 1 ? (lable[i].status = 2) : (lable[i].status = 1);
                }
                if (lable[i].status == 1) {
                    likeLable.push(lable[i].id);
                }
            }
            if (likeLable.length == 0) {
                this.showToast = "至少选择选择一项";
                this.isShow = true;
                this.getLike();
                return;
            }
            this.$ajax
                .post("/api/Basics/label", {
                    labelid: likeLable.join(",") || "",
                })
                .then(() => {
                    this.getLike();
                });
        },
        // 获取协议
        getAgree() {
            this.$ajax.post("/api/login/agreement").then((res) => {
                if (res.code == 1) {
                    this.agree[0] = res.agreement;
                    this.agree[1] = res.convention;
                    this.agree[2] = res.privacy;
                    this.$forceUpdate;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.taCont {
    height: 490px;
    overflow-y: scroll;
}
.taCont::-webkit-scrollbar {
    width: 5px;
    background: #ededed;
}
.taCont::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}
</style>
