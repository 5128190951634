<template>
    <v-overlay>
        <div class="area-box pa-7 pb-0">
            <img
                style="position: absolute;top: -40px;right: -40px;cursor: pointer;"
                src="@/assets/icons/shut.png"
                alt
                @click="$emit('closecityselect')"
            />
            <v-select
                :items="provinces"
                label="请选择省"
                item-text="label"
                item-value="value"
                return-object
                solo
                light
                @change="setProvince($event.label,$event.value)"
            ></v-select>
            <v-select
                :items="citys"
                v-if="isChooseP"
                label="请选择市"
                item-text="label"
                item-value="value"
                return-object
                solo
                light
                @change="setCity($event.lable,$event.value)"
            ></v-select>
            <v-select
                :items="areas"
                v-if="isChooseC"
                label="请选择区"
                item-text="label"
                item-value="value"
                return-object
                solo
                light
                @change="setArea($event.lable,$event.value)"
            ></v-select>
        </div>
    </v-overlay>
</template>

<script>
import provinces from "@/assets/js/province.js";
import citys from "@/assets/js/city.js";
// console.log("citys", citys, citys[14]);
import areas from "@/assets/js/area.js";
/**
 * city-select 省市区级联选择器
 * @property {String Number} z-index 弹出时的z-index值（默认1075）
 * @property {Boolean} mask-close-able 是否允许通过点击遮罩关闭Picker（默认true）
 * @property {String} default-region 默认选中的地区，中文形式
 * @property {String} default-code 默认选中的地区，编号形式
 */
export default {
    props: {
        // 通过双向绑定控制组件的弹出与收起
        cityoverlay: {
            type: Boolean,
            default: false,
        },
        // 默认显示的地区，可传类似["河北省", "秦皇岛市", "北戴河区"]
        defaultRegion: {
            type: Array,
            default() {
                return [];
            },
        },
        // 默认显示地区的编码，defaultRegion和areaCode同时存在，areaCode优先，可传类似["13", "1303", "130304"]
        areaCode: {
            type: Array,
            default() {
                return [];
            },
        },
        // 是否允许通过点击遮罩关闭Picker
        maskCloseAble: {
            type: Boolean,
            default: true,
        },
        // 弹出的z-index值
        zIndex: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            cityValue: "",
            isChooseP: false, //是否已经选择了省
            province: 0, //省级下标
            provinces: provinces,
            isChooseC: false, //是否已经选择了市
            city: 0, //市级下标
            citys: citys[0],
            isChooseA: false, //是否已经选择了区
            area: 0, //区级下标
            areas: areas[0][0],
            tabsIndex: 0,
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        isChange() {
            return this.tabsIndex > 1;
        },
        genTabsList() {
            let tabsList = [
                {
                    name: "请选择",
                },
            ];
            if (this.isChooseP) {
                tabsList[0]["name"] = this.provinces[this.province]["label"];
                tabsList[1] = {
                    name: "请选择",
                };
            }
            if (this.isChooseC) {
                tabsList[1]["name"] = this.citys[this.city]["label"];
                tabsList[2] = {
                    name: "请选择",
                };
            }
            if (this.isChooseA) {
                tabsList[2]["name"] = this.areas[this.area]["label"];
            }
            return tabsList;
        },
        uZIndex() {
            // 如果用户有传递z-index值，优先使用
            return this.zIndex ? this.zIndex : this.$u.zIndex.popup;
        },
    },
    methods: {
        init() {
            if (this.areaCode.length == 3) {
                this.setProvince("", this.areaCode[0]);
                this.setCity("", this.areaCode[1]);
                this.setArea("", this.areaCode[2]);
            } else if (this.defaultRegion.length == 3) {
                this.setProvince(this.defaultRegion[0], "");
                this.setCity(this.defaultRegion[1], "");
                this.setArea(this.defaultRegion[2], "");
            }
        },
        setProvince(label = "", value = "") {
            // console.log("label,value", label, value);
            this.provinces.map((v, k) => {
                if (value ? v.value == value : v.label == label) {
                    this.provinceChange(k);
                }
            });
        },
        setCity(label = "", value = "") {
            this.citys.map((v, k) => {
                if (value ? v.value == value : v.label == label) {
                    this.cityChange(k);
                }
            });
        },
        setArea(label = "", value = "") {
            this.areas.map((v, k) => {
                if (value ? v.value == value : v.label == label) {
                    this.isChooseA = true;
                    this.area = k;
                    this.areaChange(k);
                }
            });
        },
        close() {
            this.$emit("input", false);
        },
        tabsChange(index) {
            this.tabsIndex = index;
        },
        provinceChange(index) {
            // console.log("index", index);

            this.isChooseP = true;
            this.isChooseC = false;
            this.isChooseA = false;
            this.province = index;
            this.citys = citys[index];
            this.tabsIndex = 1;
        },
        cityChange(index) {
            // console.log("indexs", index);
            this.isChooseC = true;
            this.isChooseA = false;
            this.city = index;
            this.areas = areas[this.province][index];
            // console.log("this.areas", areas, this.province, this.areas);
            this.tabsIndex = 2;
        },
        areaChange(index) {
            this.isChooseA = true;
            this.area = index;
            let result = {};
            result.province = this.provinces[this.province];
            result.city = this.citys[this.city];
            result.area = this.areas[this.area];
            // console.log('asdjhuiwq');
            this.$emit("cityChange", result);
            this.close();
        },
    },
};
</script>
<style>
.area-box {
    position: relative;
    z-index: 99;
    background-color: #f2f2f2;
    /* background: red;
    width: 100vw;
    height: 70vh; */
}
</style>