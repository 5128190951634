<template>
    <nav id="navbar">
        <v-app-bar :class="{ white: !$vuetify.theme.dark }" flat app clipped-left>
            <v-app-bar-nav-icon @click.stop="changeMenu()"></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-bold">
                <router-link to="/" class="black--text" style="text-decoration: none">
                    <div class="d-flex" style="height: 40px; align-items:center">
                        <img style="width:40px;margin-right:5px" src="@/assets/logo-1.png" />
                        <div style="font-family: auto;">氡氡猫</div>
                    </div>
                </router-link>
                <!-- <v-btn @click="$vuetify.theme.dark = !$vuetify.theme.dark">切换</v-btn> -->
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field flat :color="$vuetify.theme.dark ? '#fff' : '#000'" hide-details append-icon="mdi-magnify" placeholder="根据关键词搜索相关内容" outlined dense clearable v-model="searchText" @click:append="search" v-on:keyup.enter="search" @click:clear="clear" class="hidden-sm-and-down"></v-text-field>
            <v-spacer></v-spacer>
            <v-menu offsetY left v-if="vuex_user">
                <template v-slot:activator="{ on: menu }">
                    <v-btn icon v-on="{ ...menu }">
                        <v-avatar size="25" class tile>
                            <img :style="{
                                    filter: $vuetify.theme.dark
                                        ? 'invert(100%)'
                                        : 'invert(0%)',
                                }" alt="Avatar" src="../assets/btn/add.png" />
                        </v-avatar>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item,index) in addMenu" :key="index" @click="openAdd(item)">
                        <v-list-item-icon>
                            <v-avatar size="26" class="ml-3" tile>
                                <img :style="{
                                        filter: $vuetify.theme.dark
                                            ? 'invert(100%)'
                                            : 'invert(0%)',
                                    }" alt="Avatar" :src="require( `../assets/btn/`+item.img+`.png`)" />
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-title class="mr-6">{{item.name}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- 消息 -->
            <v-menu :close-on-content-click="false" offset-y left v-if="vuex_user">
                <template v-slot:activator="{ on }">
                    <v-btn icon class="mr-3" v-on="on" ref="msgBtn">
                        <v-avatar size="25" class tile>
                            <img :style="{
                                    filter: $vuetify.theme.dark
                                        ? 'invert(100%)'
                                        : 'invert(0%)',
                                }" alt="Avatar" src="../assets/btn/information.png" />
                        </v-avatar>
                    </v-btn>
                </template>
                <Msg></Msg>
            </v-menu>
            <!-- </template>
                <span>Notifications</span>
            </v-tooltip>-->
            <!-- 个人中心 -->
            <v-btn v-if="!vuex_user" small router @click="toLogin()" elevation="1">登录</v-btn>
            <v-menu offset-y left :close-on-content-click="false" :value="userMenu" v-else>
                <template v-slot:activator="{ on, attrs }">
                    <div class="avatarBorder">
                        <v-avatar  size="25" v-bind="attrs" v-on="on" ref="userAvatar" v-if="vuex_user.headimg" @click="userMenu=true;winId=0" style="cursor: pointer;margin-top: -1px;">
                            <v-img ref="header" :src="vuex_baseUrl+vuex_user.headimg" :lazy-src="vuex_base64Avatar" />
                        </v-avatar>
                        <v-avatar class="white--text headline" ref="userAvatar" style="cursor: pointer" v-else size="25" color="red" v-on="on" >{{vuex_user.username.split('')[0]}}</v-avatar>
                    </div>
                </template>
                <User @close="userMenu=false" :wid="winId" @getinfo="getUserInfo"></User>
            </v-menu>
        </v-app-bar>

        <v-navigation-drawer app :mini-variant-width="100" :mini-variant="vuex_miniVariant" v-model="drawer" @transitionend="transitionend" width="240" :clipped="$route.name !== 'Watch'" :temporary="$route.name === 'Watch'" id="nav">
            <!-- :fixed="$route.name == 'Detail'" -->
            <div tag="div" class="v-navigation-drawer__content" v-bar>
                <v-list dense nav class="py-0" tag="div">
                    <v-list-item :class="{
                            'hidden-lg-and-up':
                                $route.name === 'Watch' ? false : true,
                        }">
                        <!-- <v-app-bar-nav-icon @click="drawer = !drawer" class="mr-5"></v-app-bar-nav-icon> -->
                        <v-app-bar-nav-icon @click.stop="changeMenu()" class="mr-5"></v-app-bar-nav-icon>

                        <v-toolbar-title class="font-weight-bold">Logo</v-toolbar-title>
                    </v-list-item>
                    <v-divider class="hidden-lg-and-up"></v-divider>
                    <div v-for="parentItem in items" :key="parentItem.header">
                        <v-subheader v-if="parentItem.header" class="py-4 pl-3 subtitle-1 font-weight-bold text-uppercase">{{ parentItem.header }}</v-subheader>
                        <div v-for="(item, i) in parentItem.pages" :key="item.title">
                            <v-list-item v-if="item.title == '频道' || item.title == '专栏' || item.title == '图墙'" link class="mb-0" :class=" { 'v-list-item--active': vuex_mode == item.link,'listitems':vuex_miniVariant} " router @click="vuexChange(item.link)" exact active-class="active-item">
                                <v-list-item-icon v-if="parentItem.header !== 'Subscriptions'" :class="vuex_miniVariant?'listitemicon':''">
                                    <div class="" :class="vuex_miniVariant?'mt-2': ''">

                                        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                                        <v-avatar size="26" class="ml-3" tile v-else>
                                            <img alt="Avatar" src="@/assets/icon_slices/dy_2.png" />
                                        </v-avatar>
                                    </div>
                                    <div v-if="vuex_miniVariant" style="white-space: nowrap;">{{ item.title }}</div>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium subtitle-2">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <!-- v-else -->
                            <!-- link -->
                            <!-- :to="item.link" -->
                            <v-list-item link v-else :to="item.link" @click="leftNavClick(item)" class="mb-0" router exact active-class="active-item" :class="vuex_miniVariant?'listitems': ''">
                                <v-list-item-icon v-if="parentItem.header !== 'Subscriptions'" :class="vuex_miniVariant?'listitemicon':''">
                                    <div :class="vuex_miniVariant?'mt-2': ''">
                                        <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                                        <v-avatar size="24" class tile v-else>
                                            <router-link :to="item.link" custom v-slot="{ isExactActive }">
                                                <img v-if="isExactActive" alt="Avatar" src="../assets/icon_slices/dy_2.png" />
                                                <img v-if="!isExactActive" alt="Avatar" src="../assets/icon_slices/dy_1.png" />
                                            </router-link>
                                        </v-avatar>
                                    </div>
                                    <div v-if="vuex_miniVariant" style="white-space: nowrap;">{{ item.title }}</div>
                                </v-list-item-icon>
                                <v-list-item-avatar v-else class="mr-5">
                                    <img :src="`https://randomuser.me/api/portraits/men/${i}.jpg`" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium subtitle-2">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <v-divider class="mt-2 mb-2" v-if="parentItem.pages"></v-divider>

                    </div>
                    <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: none;">
                        <div class="caption mt-5" style="color:#757575">
                            ICP备：粤ICP备19056671号-2
                        </div>
                          <div class="caption" style="color:#757575">
                            邮箱：rncat@eastbillows.com
                        </div>
                    </a>
                    <a href="https://rncats.com" style="text-decoration: none;">
                        <div class="caption mt-2" style="color:#757575">
                            © 2021 rncats.com
                        </div>
                    </a>
                    <img src="@/assets/eehx.png" style="width:80px">
                    <!-- <span v-for="link in links" :key="link.text">
                        <span v-if="link.text === 'Terms'" class="mb-2 d-block"></span>
                        <v-btn
                            href
                            router
                            :to="link.link"
                            text
                            class="px-1 text-capitalize"
                            small
                        >{{ link.text }}</v-btn>
                    </span>-->
                </v-list>
            </div>
        </v-navigation-drawer>
        <add-modal :open-dialog="addModalShow" :type="addType" v-on:closeDialog="modelClose"></add-modal>
    </nav>
</template>

<script>
import Msg from "@/components/CardMsg";
import User from "@/components/CardUser";
import AddModal from  "@/components/AddModal";
export default {
    components: {
        Msg,
        User,
        AddModal
    },
    data: () => ({
        // vuex_miniVariant: false,
        // drawer: false,
        addMenu:[{
            name:'视频',
            img:'video',
            id:1
        },{
            name:'文章',
            img:'art',
            id:2
        },{
            name:'图片',
            img:'photo',
            id:3
        }],
        addModalShow:false,
        addType:'',
        drawer: true,
        items: [
            {
                header: null,
                pages: [
                    { title: "主页", link: "/", icon: "mdi-home" },
                    { title: "探索", link: "/Explore", icon: "mdi-compass" },
                    { title: "订阅", link: "/Explore?type=1", icon: "mdi-star-box"},
                    { title: "最热", link: "/Explore?type=3", icon: "mdi-fire"},
                    { title: "最新", link: "/Explore?type=4", icon: "mdi-alarm"},
                ],
            },
            {
                header: null,
                pages: [
                    {
                        title: "频道",
                        link: "video",
                        icon: "mdi-play-box-multiple",
                    },
                    {
                        title: "专栏",
                        link: "art",
                        icon: "mdi-history",
                    },
                    {
                        title: "图墙",
                        link: "photo",
                        icon: "mdi-play-box-outline",
                    },
                ],
            },
            {},
            {}
        ],
        searchText: "",
        newsList: [], //消息
        newsType: 0,
        page: 1,
        reveal: false,
        msgId: "",
        msgData: [],
        msgPage: 1,
        userMenu: "",
        winId: 0,
    }),
    methods: {
        //打开发布弹框
        openAdd(item){
            this.addType=item;
            this.addModalShow=true;
        },
        modelClose(){
            this.addModalShow=false;
        },
        toLogin() {
            this.$router.push('/signin');
        },
        leftNavClick(item) {
            console.log(item);
            if (item.type) {
                if (!this.vuex_token) {
                    this.$router.push("/signin");
                }
                this.$refs.userAvatar.$el.click();
                this.winId = item.type;
            }
        },
        changeMenu() {
            
            if (
                this.$route.name == "Home" ||
                this.$route.name == "Explore" ||
                this.$route.name == "Channels" ||
                this.$route.name == "Collect"
            ) {
                console.log('logs');
                this.vuex("vuex_miniVariant", !this.vuex_miniVariant);
                if (this.vuex_miniVariant) {
                    this.items[2] = {};
                    this.items[3] = {};
                } else {
                    this.getMyHome();
                }
            } else {
                this.drawer = !this.drawer
            }
        },
        getMyHome() {
            if (this.vuex_user.id) {
                this.items[2] = {
                    header: null,
                    pages: [
                        {
                            title: "我的主页",
                            link: "/channels/" + this.vuex_user.id,
                            icon: "mdi-account-details",
                        },
                        {
                            title: "我的收藏",
                            link: "/collect/",
                            icon: "mdi-rhombus-split",
                        },
                       
                        {
                            title: "我的喜欢",
                            icon: "mdi-heart",
                            type: 3
                        },
                    ],
                };
            } else {
                this.items[2] = {};
            }
            this.items[3] = {
                header: null,
                pages: [
                   
                    {
                        title: "功能设置",
                        icon: "mdi-cog-outline",
                        type: 5
                    },
                    {
                        title: "协议与公约",
                        icon: "mdi-note-text-outline",
                        type: 6
                    },
                    {
                        title: "反馈与帮助",
                        icon: "mdi-help-circle-outline",
                        type: 7
                    },
                ],
            }
        },
        transitionend(e) { },
        modechange(parme) {
            this.vuex("vuex_mode", parme.link);
        },
        clear(e) {
            if (this.$route.path == "/") {
                this.vuex("vuex_indexkey", "");
            }
        },
        search() {
            if (this.$route.path == "/") {
                if (!this.searchText) {
                    this.vuex("vuex_indexkey", "");
                    return;
                }
                this.vuex("vuex_indexkey", this.searchText);
            }
            return;
            this.$router.push({
                name: "Search",
                query: { "search-query": this.searchText },
            });
        },
        vuexChange(parme) {
            if (this.$route.name == "Detail" || this.$route.name == "Collect") {
                console.log("thise", this.$route.name);
                return;
            } else {
                console.log("else");
            }
            this.vuex("vuex_mode", parme);
        },
        // 获取个人信息
        getUserInfo() {
            let that = this;
            that.userMenu = false;
            this.$ajax.post("/api/Basics/getinformation").then((res) => {
                if (res.code == 1) {
                    that.vuex("vuex_user", res.users);
                    let vuex_account = this.vuex_account;
                    let account = vuex_account[res.users.username];
                    if (!account) {
                        account = { token: "", user: [] };
                    }
                    account.token = this.vuex_token;
                    account.user = res.users;
                    vuex_account[res.users.username] = account;
                    that.vuex("vuex_account", vuex_account);
                    this.getMyHome();
                }
            });
        },
    },
    mounted() {
        this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true;
        this.drawer = this.$route.name === "Watch" ? false : this.drawer;
        this.drawer = false; //test
        this.getUserInfo();
        this.getMyHome();
    },
};
</script>

<style lang="scss">
.listitems {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.listitemicon {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
}
#navbar {
    .active-item {
        .v-list-item__icon {
            color: #1c1c1c !important;
        }
    }
    .v-navigation-drawer__border {
        width: 0 !important;
    }

    .vuebar-element {
        height: 250px;
        width: 100%;
        max-width: 500px;
        background: #dfe9fe;
    }

    .vb > .vb-dragger {
        z-index: 5;
        width: 10px;
        right: 0;
    }

    .vb > .vb-dragger > .vb-dragger-styler {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: rotate3d(0, 0, 0, 0);
        transform: rotate3d(0, 0, 0, 0);
        -webkit-transition: background-color 100ms ease-out,
            margin 100ms ease-out, height 100ms ease-out;
        transition: background-color 100ms ease-out, margin 100ms ease-out,
            height 100ms ease-out;

        margin: 5px 5px 5px 0;
        border-radius: 20px;
        height: calc(100% - 10px);
        display: block;
    }

    .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
        width: 10px;
        background-color: #e0e0e0;
    }

    .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(48, 121, 244, 0.3);
        background-color: rgba(255, 255, 255, 0.3);
    }

    .vb > .vb-dragger:hover > .vb-dragger-styler {
        margin: 0px;
        width: 10px;
    }

    .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(48, 121, 244, 0.5);
        margin: 0px;
        height: 100%;
    }

    .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
        background-color: rgba(48, 121, 244, 0.5);
    }
}
.msgAlert {
    background: linear-gradient(-6deg, #000000, #7a7a7a);
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    padding: 0 5px;
}
.avatarBorder{
    border: 2px solid #555;
    border-radius: 50%;
}
</style>
