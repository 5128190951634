/*
 * @Descripttion: 
 */
let util = {};

util.getDateDiff = function(dateTimeStamp) {
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var result = '';
    var now = new Date().getTime();
    var diffValue = now - dateTimeStamp;
    if (diffValue < 0) {
        console.log("时间不对劲,服务器创建时间与当前时间不同步");
        return result = "刚刚";
    }
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    if (parseInt(dayC) > 30) {
        console.log('dateTimeStamp', dateTimeStamp);
        result = "" + dateFormat(dateTimeStamp);
    } else if (parseInt(dayC) > 1) {
        result = "" + parseInt(dayC) + "天前";
    } else if (parseInt(dayC) == 1) {
        result = "昨天";
    } else if (hourC >= 1) {
        result = "" + parseInt(hourC) + "小时前";
    } else if (minC >= 5) {
        result = "" + parseInt(minC) + "分钟前";
    } else
        result = "刚刚";
    return result;
}



function dateFormat(date, fmt = "YYYY-mm-dd HH:MM") {
    date = new Date(date);
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}




export default util