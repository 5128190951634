import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from './util'
import vuescroll from 'vuescroll';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
// 你可以在这里设置全局配置
Vue.use(vuescroll, {
    ops: {}, // 在这里设置全局默认配置
    name: 'myScroll' // 在这里自定义组件名字，默认是vueScroll
});
import waterfall from 'vue-waterfall2'

Vue.use(waterfall)
// 引入uView提供的对vuex的简写法文件
import module from './store/$u.mixin.js';

Vue.mixin(module);
import vuetify from './plugins/vuetify'

import './config/validate'

import requests from './config/axios'
Vue.prototype.$ajax = requests;

Vue.prototype.$util = util;

// eslint-disable-next-line
// const _hmt = _hmt || [];
// eslint-disable-next-line
// window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
// (function () {
//     const hm = document.createElement('script');
//     hm.src = "https://hm.baidu.com/hm.js?1ab3db3fba15fdf838a42cc875280b97";
//     const s = document.getElementsByTagName('script')[0];
//     s.parentNode.insertBefore(hm, s);
// }());
new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app')
